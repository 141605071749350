<template>
  <section>
    <el-tree ref="sortTree" :check-strictly="true" :data="dataList" @check="toNode" show-checkbox node-key="id" style="padding-right: 10px;width: 150px;" :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{node, data}">
        <span>{{ data.title }}</span>
      </span>
    </el-tree>
  </section>
</template>

<script>
import { setSubject } from '@/api/detection'
import { selectAllType } from '@/api/dataOutput'
import event from '@/utils/eventHub.js'
  export default {
    mounted () {
      this.listAllNode()
    },
    data () {
      return {
        currentIndex: 0,
        dataList: [],
        top: 130
      }
    },
    methods: {
      async listAllNode () {
        const res = await selectAllType()
        this.dataList = res.data
      },
      toNode (node, data) {
        let checkedKeys = data.checkedKeys;
        let currKey = node.id;
        this.$refs.sortTree.setCheckedKeys([currKey]);
        // this.changeNodeId = node.id
        event.$emit('changeNodeId', node.id)
      }
    }
  }
</script>

<style>
</style>
