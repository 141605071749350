<template>
  <div class="analysis">
    <section class="dimension-statistics">
      <section class="filter">
        <label class="title">发布时间:
          <el-radio-group v-model="form.type" size="mini">
            <el-radio label="1" border>今天</el-radio>
            <el-radio label="2" border>最近三天</el-radio>
            <el-radio label="3" border>最近七天</el-radio>
            <el-radio label="4" border>最近三十天</el-radio>
            <el-radio :disabled="isTrial" label="5" border>自定义</el-radio>
          </el-radio-group>

          <el-date-picker v-if="form.type === '5' && !isTrial"
                          size="small"
                          unlink-panels
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="timeRange"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </label>
        <div class="btn-group">
          <el-button type="primary" size="mini" class="search-btn" @click="refresh">查询</el-button>
        </div>

      </section>
      <section class="content">
        <div class="blur-overlay" v-if="!nodePermission">
          <div class="blur-message">
            <div style="text-align: center; margin-bottom: 20px">
              <svg t="1711359563240" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9276" width="64" height="64"><path d="M868.935 1008.63H155.067c-40.657 0-72.291-31.631-72.291-67.773V443.863c0-36.144 31.631-67.773 72.291-67.773h713.868c40.657 0 72.291 31.631 72.291 67.773v496.994c0 36.144-31.631 67.773-72.291 67.773zM512 543.259c-58.732 0-108.432 45.187-108.432 99.402 0 36.144 22.586 67.773 54.218 85.849v94.887c0 27.108 22.586 49.696 54.218 49.696s54.218-22.586 54.218-49.696V728.51c31.631-18.071 54.218-49.696 54.218-85.849 0-54.218-49.696-99.402-108.432-99.402z m0-429.228c-117.471 0-216.867 90.356-216.867 198.797H186.701c0-162.655 144.582-298.202 320.79-298.202s320.79 135.546 320.79 298.202H719.849c9.041-112.951-90.356-198.797-207.836-198.797z m0 0z" p-id="9277" fill="#4471C1"></path></svg>
            </div>
            <span>{{ isTrial ? '已锁定，请联系客服人员' : '主题数据不可查看' }}</span>
          </div>
        </div>
        <div class="main-statistics item">
          <div class="top-wrap">
            <h3 class="title">舆情主图态势图</h3>
          </div>
          <div class="noData" v-show="noMain">暂无数据</div>
          <div id="mainCharts" class="charts" v-show="!noMain"></div>
        </div>
        <div class="situation-statistics item">
          <div class="top-wrap">
            <h3 class="title">舆情态势统计图</h3>
          </div>
          <div class="noData" v-show="noSituation">暂无数据</div>
          <div id="situationCharts" class="charts" v-show="!noSituation"></div>
        </div>
        <div class="carrier-statistics item">
          <div class="top-wrap">
            <h3 class="title">载体分布统计图</h3>
          </div>
          <div class="noData" v-show="noCarrier">暂无数据</div>
          <div id="carrierCharts" class="charts" v-show="!noCarrier"></div>
        </div>
        <div class="source-statistics item">
          <div class="top-wrap">
            <h3 class="title">舆情来源网站统计</h3>
          </div>
          <div class="noData" v-show="noSource">暂无数据</div>
          <div id="sourceCharts" class="charts" v-show="!noSource"></div>
        </div>
        <div class="main-statistics item">
          <div class="top-wrap">
            <h3 class="title">原创/转发统计图</h3>
          </div>
          <div class="noData" v-show="noForward">暂无数据</div>
          <div id="forwardCharts" class="charts" v-show="!noForward"></div>
        </div>
        <div class="situation-statistics item">
          <div class="top-wrap">
            <h3 class="title">标签统计图</h3>
          </div>
          <div class="noData" v-show="noDimension">暂无数据</div>
          <div id="dimensionCharts" class="charts" v-show="!noDimension"></div>
        </div>
        <div class="dimension-statistics" style="height: 480px">
          <div class="top-wrap">
            <h3 class="title">词频云图</h3>
            <el-button :disabled="isTrial" @click="showDiyWordDialog" style="position: absolute; margin-top: -37px; right: 303px;" type="primary" size="mini">词频自定义</el-button>
            <el-dropdown :disabled="isTrial" style="position: absolute; margin-top: -37px; right: 223px;" @command="handleChangeWordShape">
              <el-button :disabled="isTrial" type="normal" size="mini">
                {{ shape }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <!--              <el-dropdown-item command="方形">方形</el-dropdown-item>-->
                <el-dropdown-item command="圆形">圆形</el-dropdown-item>
                <el-dropdown-item command="云朵">云朵</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown :disabled="isTrial" style="position: absolute; margin-top: -37px; right: 120px;" @command="handleChangeWordTotal">
              <el-button :disabled="isTrial" type="normal" size="mini">
                TOP {{ form.wordTotal }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="50">TOP 50</el-dropdown-item>
                <el-dropdown-item :command="100">TOP 100</el-dropdown-item>
                <el-dropdown-item :command="200">TOP 200</el-dropdown-item>
                <el-dropdown-item :command="300">TOP 300</el-dropdown-item>
                <el-dropdown-item :command="500">TOP 500</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button :disabled="isTrial" type="primary" size="mini" style="float: right; position: absolute; right: 10px; margin-top: -37px" @click="exportWordCloud">导出词云数据</el-button>
          </div>
          <div class="noData" v-show="noCipin">暂无数据</div>
          <div id="cipinChart" class="charts" style="width: 100%;padding: 0px;margin-top: -20px" v-show="!noCipin"></div>
        </div>
        <div class="dimension-statistics">
          <div class="top-wrap">
            <h3 class="title">位置分布统计图</h3>
          </div>
          <div class="noData" v-show="noLocation">暂无数据</div>
          <div id="locationChart" class="charts" v-show="!noLocation"></div>
          <div style="width: 40%;float: left; padding-left: 40px; margin-top: 50px" v-show="!noLocation">
            <div v-for="item in this.locationText" style="margin-bottom: 15px">
              <span style="margin-bottom: 5px;font-size: 10px">{{ item.name }} ({{ item.value }}条)</span><br><br>
              <el-progress :text-inside="true" :stroke-width="10" color="#5470C6" :percentage="item.per" :show-text="false"></el-progress>
            </div>
          </div>
        </div>
        <el-dialog
          title="词频自定义"
          :visible.sync="diyWordDialog"
          width="45%">
          <div v-loading="loadingDiyWord" style="padding: 0 20px; height: 400px; overflow: auto" class="diy-word-cloud">
            <div style="width: calc(50% - 1px); float: left; min-height: 100px; padding-right: 20px">
              <div style="padding-bottom: 10px">删除</div>
              <div>
                <el-tag
                  :key="tag"
                  v-for="tag in delWordArray"
                  closable
                  type="danger"
                  :disable-transitions="false"
                  @close="delhandleClose(tag)">
                  {{tag.word}}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="delinputVisible"
                  v-model="delinputValue"
                  ref="delsaveTagInput"
                  size="small"
                  @keyup.enter.native="delhandleInputConfirm"
                  @blur="delhandleInputConfirm"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="delshowInput">+ 增加删除词</el-button>
              </div>
            </div>
            <div style="width: 1px; height: 400px; background-color: lightgray; float: left"></div>
            <div style="width: 50%; float: left; min-height: 100px; padding-left: 20px">
              <div style="padding-bottom: 10px">锁定</div>
              <div>
                <el-tag
                  :key="tag"
                  v-for="tag in lockWordArray"
                  closable
                  type="success"
                  :disable-transitions="false"
                  @close="lockhandleClose(tag)">
                  {{tag.word}}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="lockinputVisible"
                  v-model="lockinputValue"
                  ref="locksaveTagInput"
                  size="small"
                  @keyup.enter.native="lockhandleInputConfirm"
                  @blur="lockhandleInputConfirm"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="lockshowInput">+ 增加锁定词</el-button>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
          <el-button @click="diyWordDialog = false">关 闭</el-button>
        </span>
        </el-dialog>
      </section>
    </section>
  </div>
</template>

<script>
import {
  getWebStat,
  getCarrDis,
  sortSituStat,
  mediaSort,
  situationSort,
  sortification,
  locationStat,
  forwardOriginal,
  mapJSON,
  getWordCloud,
  exportWordCloud,
  fetchDiyWords,
  addDiyWord,
  delDiyWord
} from '@/api/sortStatistics'
import { getNodePermission } from '@/api/detection'
import { getIsTrial } from '@/utils/auth'
export default {
  data () {
    return ({
      isTrial: true,
      nodePermission: false,
      timeRange: '',
      form: {
        type: '4',
        startDate: '',
        endDate: '',
        sortsId: '',
        sortId: '',
        wordTotal: 200
      },
      carrList: [],
      noSource: false,
      noForward: false,
      noLocation: false,
      noCarrier: false,
      noSituation: false,
      noMain: false,
      noDimension: false,
      noCipin: false,
      tu1: '',
      tu2: '',
      tu3: '',
      tu4: '',
      tu5: '',
      locationText: [],
      shape: '圆形',
      diyWordDialog: false,
      deldynamicTags: ['标签一', '标签二', '标签三'],
      delinputVisible: false,
      delinputValue: '',
      lockdynamicTags: ['标签1', '标签2', '标签3'],
      lockinputVisible: false,
      lockinputValue: '',
      delWordArray: [],
      lockWordArray: [],
      loadingDiyWord: false
    })
  },
  watch: {
    'timeRange': {
      handler (val) {
        if (val.length > 0) {
          this.form.startDate = this.formatDate(val[0])
          this.form.endDate = this.formatDate(val[1])
        }
      },
      deep: true,
      immediate: true
    },
    getnewValue: {
      async handler (newValue, oldValue) {
        let nodeId = this.$route.params.nodeId
        if (nodeId && nodeId === '0') {
          this.$message.warning('主题不可查看数据统计')
          this.nodePermission = false
          // this.$router.back()
        } else {
          await this.fetchNodePermission()
          if (this.nodePermission) {
            this.currentPage = 1
            this.refresh()
          }
        }
      }
    }
  },
  computed: {
    // 监听数据
    getnewValue () {
      return this.$route.path
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  async mounted () {
    this.isTrial = await getIsTrial()
    await this.fetchNodePermission()
    if (this.nodePermission) {
      this.tu1 = this.$echarts.init(document.getElementById('sourceCharts'))
      this.tu2 = this.$echarts.init(document.getElementById('carrierCharts'))
      this.tu3 = this.$echarts.init(document.getElementById('situationCharts'))
      this.tu4 = this.$echarts.init(document.getElementById('mainCharts'))
      this.tu5 = this.$echarts.init(document.getElementById('locationChart'))
      this.refresh()
      window.addEventListener('resize', this.handleResize)
    }
  },
  methods: {
    handleResize () {
      this.tu1.resize()
      this.tu2.resize()
      this.tu3.resize()
      this.tu4.resize()
      this.tu5.resize()
    },
    async fetchNodePermission () {
      let nodeId = this.$route.params.nodeId
      let msId = this.$route.params.id
      const res = await getNodePermission({ msId: msId, nodeId: nodeId })
      this.nodePermission = res.data
    },
    showDiyWordDialog () {
      this.diyWordDialog = true
      this.loadDiyWords()
    },
    async loadDiyWords () {
      this.loadingDiyWord = true
      const res = await fetchDiyWords()
      this.delWordArray = []
      this.lockWordArray = []
      var _this = this
      setTimeout(function () {
        _this.loadingDiyWord = false
        _this.delWordArray = res.data.delWordArray
        _this.lockWordArray = res.data.lockWordArray
      }, 500)

    },
    refresh () {
      this.form.sortsId = this.$route.params.nodeId
      this.form.sortId = this.$route.params.nodeId
      this.form.startDate = this.timeRange[0]
      this.form.endDate = this.timeRange[1]
      this.queryWebStat()
      this.queryCarr()
      this.situation()
      this.monStat()
      this.dimension()
      this.locationStat()
      this.forward()
      this.wordCloud()
    },
    // 主图
    async monStat () {
      this.tu4.showLoading({
        text: '加载中...',
        color: '#5470C6',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await situationSort(this.form)
      if (res.data && res.data.series.length === 0 || res.data === null) {
        this.noMain = true
      } else {
        this.noMain = false
        this.main(res)
      }
      this.tu4.hideLoading()
    },
    // 来源网站
    async queryWebStat () {
      this.tu1.showLoading({
        text: '加载中...',
        color: '#5470C6',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await getWebStat(this.form)
      if (res.data === null || res.data.length === 0) {
        this.noSource = true
      } else {
        this.noSource = false
        this.source(res)
      }

      this.tu1.hideLoading()
    },
    // 载体分布
    async queryCarr () {
      this.tu2.showLoading({
        text: '加载中...',
        color: '#5470C6',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await getCarrDis(this.form)
      this.carrList = res.data
      if (res.data === null || res.data.length === 0) {
        this.noCarrier = true
      } else {
        this.noCarrier = false
        var totalArr = []
        for(var i=0; i<res.data.series[0].data.length; i++){
          var total = 0
          res.data.series.forEach((item,index)=>{
            total += res.data.series[index].data[i]
          })
          totalArr.push(total)
        }
        var obj = {
            name: '总量',
            type: 'bar',
            barGap: '-100%',
            itemStyle: {
                normal: {
                    color: 'rgba(128, 128, 128, 0.3)' // 仍为透明
                }
            },
            data: totalArr,
          }
        this.carrList.series.splice(0,0,obj)
        this.carrier(res)
      }

      this.tu2.hideLoading()
    },
    source (res) {
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        legend: {
          orient: 'vertical',
          x: 'left',
          data: res.data.legend
        },
        series: [
          {
            name: '网站来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#fff',
              borderWidth: 1
            },
            color: ['#5470C6','#92CC76','#FAC858','#ED6666','#73C0DE','#3BA272','#FC8452','#9A60B4','#EA7CCC','#879BD8','#BBBBBB'],
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: res.data.series
          }
        ]
      }

      this.tu1.setOption(option)
    },
    carrier (res) {
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        color: ['#3372C7', '#C5191B', '#92CC7B', '#6B759A', '#FC8456', '#0898C2', '#FAC861', '#BCBCBC', '#BDE0F5', '#1F1E1C', '#F5A5B4'],
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        legend: {
          data: this.carrList.legend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: this.carrList.xAxis
        },
        series: this.carrList.series
      }

      this.tu2.setOption(option)
    },
    // 舆情态势统计图
    async situation () {
      this.tu3.showLoading({
        text: '加载中...',
        color: '#5470C6',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await sortSituStat(this.form)
      if (res.data.xAxis === null) {
        this.noSituation = true
      } else {
        this.noSituation = false
        var totalArr = []
        if(res.data && res.data.series){
          res.data.series[0].yBadData.forEach((item,index)=>{
            totalArr.push(item+res.data.series[0].yNotBadData[index]+res.data.series[0].yMGData[index])
          })
        }
      }
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        color: ['#3372C7', '#F56C6C', '#ffdb8a'],
        legend: {
          data: ['正面', '敏感', '负面']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.data ? res.data.xAxis : []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '总量',
            type: 'line',
            symbol: 'none',
            itemStyle: {
                normal: {
                    color: 'rgba(128, 128, 128, 0)' // 仍为透明
                }
            },
            data: totalArr
          },{
            name: '正面',
            type: 'line',
            stack: '总量1',
            symbol: 'none',
            data: res.data ? res.data.series[0].yNotBadData : [],
            smooth: true
          },
          {
            name: '敏感',
            type: 'line',
            stack: '总量2',
            symbol: 'none',
            data: res.data ? res.data.series[0].yMGData : [],
            smooth: true
          },
          {
            name: '负面',
            type: 'line',
            stack: '总量3',
            symbol: 'none',
            data: res.data ? res.data.series[0].yBadData : [],
            smooth: true
          }
        ]
      }

      this.tu3.setOption(option)
      this.tu3.hideLoading()
    },
    main (res) {
      res.data.series.forEach((item, index) => {
        item.symbol = 'none'
        item.smooth = true
      })
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: res.data.legend
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: res.data.xAxis
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: res.data.series
      }
      this.tu4.setOption(option, true)
    },
    // 标签维度柱状图
    async dimension () {
      let myChart = this.$echarts.init(document.getElementById('dimensionCharts'))
      myChart.showLoading({
        text: '加载中...',
        color: '#5470C6',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await mediaSort(this.form)
      if (res.data.xAxis.length === 1 && res.data.xAxis[0] === null) {
        this.noDimension = true
      } else {
        this.noDimension = false
      }
      myChart.hideLoading()
      let option = {
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}'
        },
        series: [{
          name: '标签',
          type: 'wordCloud',
          shape: 'diamond',
          size: ['100%', '100%'],
          textRotation: [0, 45, 90, -45],
          rotationRange: [0, 0],
          textPadding: 0,
          drawOutOfBound: true,
          sizeRange: [10, 30],
          autoSize: {
            enable: true,
            minSize: 14
          },
          textStyle: {
            fontFamily: 'sans-serif',
            // Color can be a callback function or a color string
            color: function () {
              // Random color
              return 'rgb(' + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
              ].join(',') + ')'
            }
          },
          emphasis: {
            focus: 'self',
            textStyle: {
              textShadowBlur: 10,
              textShadowColor: '#333'
            }
          },
          data: res.data.series
        }]
      }
      myChart.setOption(option)
    },
    // 词频云图
    async wordCloud () {
      let wordChart = this.$echarts.init(document.getElementById('cipinChart'))
      wordChart.showLoading({
        text: '加载中...',
        color: '#5470C6',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await getWordCloud(this.form)
      if ((res.data && res.data.length === 0) || res.data === null) {
        this.noCipin = true
      } else {
        this.noCipin = false
      }
      wordChart.hideLoading()
      var maskImage = new Image()
      maskImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAgCAYAAAB3j6rJAAAAAXNSR0IArs4c6QAAAbtJREFUWEft179LVVEcAPCP/0EKEUVGgxAuQRku0RBEEDi05KJLEAQRughBi7ZI1FQNQZAgRUPiEjREDtESERQhCg1RoFCD7oKD8hUvvB73l0/Oew7d6XHe95zv5xy+95xzuxyQp+uAOOwFchQDOIez+INF/MD7/U6oLmQCD0uSfcALzLQKqoIcwRsM1kwwhXs1Y/8JK4P0YL2FQVvClEE+72Elmr1DeLvbeAjHsFw2qSLIHdxvYTWyLp8wjru4utu4hNmiWiuCfMfpfUDKut7Es+aAPEgU6N9EiGzYYcw15siDXMa7xJDnuFEFiaqfTAyJfediGaQPj3ElMeQWnhZBruEJokZSP4exlgcJxOvU2RvG/4KokzgSNqM9irUb8br2thGSpVrAdawGZAyPOoDIUn7DhYB8jB8dhETq6YD8wskOQ7YCstVhxE76gKQ8V2rPMSAvMVK7R6LAgLRjS6/i/8wOva84UxWd8P8HGeQ4VhImKht6NTbTxmtA7P+vcKnNoLiALebdR+JkvI3+xKB5jGIje32L8p3CeZyoCaq7H8WBFxfp30XXgJr50oRVfWClyZoz6n9I86JsA8HZPRHjzaKoAAAAAElFTkSuQmCC'
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}'
        },
        series: [{
          sizeRange: [14, 80],
          name: '热词',
          type: 'wordCloud',
          maskImage: this.shape === '云朵' ? maskImage : null,
          shape: this.shape === '圆形' ? 'circle' : 'square',
          size: ['100%', '100%'],
          textPadding: 0,
          textStyle: {
            fontFamily: 'sans-serif',
            // Color can be a callback function or a color string
            color: function () {
              // Random color
              return 'rgb(' + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
              ].join(',') + ')'
            }
          },
          emphasis: {
            focus: 'self',
            textStyle: {
              textShadowBlur: 10,
              textShadowColor: '#333'
            }
          },
          data: res.data
        }]
      }
      wordChart.setOption(option)
    },
    // 导出词云
    async exportWordCloud () {
      const res = await exportWordCloud(this.form)
      if (res.code === 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    handleChangeWordTotal (total) {
      this.form.wordTotal = total
      this.wordCloud()
    },
    handleChangeWordShape (shape) {
      this.shape = shape
      this.wordCloud()
    },
    // 地理位置
    async locationStat () {
      this.tu5.showLoading({
        text: '加载中...',
        color: '#5470C6',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await locationStat(this.form)
      var data = []
      if (res.data.length === 1 && res.data[0].amount === 0) {
        this.noLocation = true
      } else {
        this.noLocation = false
        if (res.data) {
          for (var i = 0; i < res.data.length; i++) {
            var item = { name: res.data[i].area, value: res.data[i].amount }
            data.push(item)
          }
        }
        data.sort(this.compare('value'))
        this.loadMapChart(this.tu5, data)
        var a = data[0].value
        data[0].per = 100
        for (var i = 0; i < data.length; i++) {
          data[i].per = (data[i].value.toFixed(2) / a.toFixed(2)) * 100
        }
        this.locationText = data.slice(0, 10)
      }
    },
    compare (p) { // 这是比较函数
      return function (m, n) {
        var a = m[p]
        var b = n[p]
        return b - a // 升序
      }
    },
    // 原创转发
    async forward () {
      let myChart = this.$echarts.init(document.getElementById('forwardCharts'))
      myChart.showLoading({
        text: '加载中...',
        color: '#5470C6',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      var totalArr = []
      const res = await forwardOriginal(this.form)
      if (res.data === null || res.data.dateList.length === 0) {
        this.noForward = true
        return
      } else {
        this.noForward = false
        res.data.original.forEach((item,index)=>{
          totalArr.push(item+res.data.forward[index])
        })
      }
      myChart.hideLoading()
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // Use axis to trigger tooltip
            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
          data: ['原创', '转发']
        },
        grid: {
          left: '7%',
          right: '4%',
          bottom: '10%',
          containLabel: false
        },
        yAxis: {
          type: 'value'
        },
        xAxis: {
          type: 'category',
          data: res.data.dateList
        },
        series: [
          {
            name: '总量',
            type: 'bar',
            barGap: '-100%',
            itemStyle: {
                normal: {
                    color: 'rgba(128, 128, 128, 0.3)' // 仍为透明
                }
            },
            data: totalArr
        },{
            name: '原创',
            type: 'bar',
            stack: 'total',
            label: {
              show: false
            },
            emphasis: {
              focus: 'series'
            },
            data: res.data.original
          },
          {
            name: '转发',
            type: 'bar',
            stack: 'total',
            label: {
              show: false
            },
            emphasis: {
              focus: 'series'
            },
            data: res.data.forward
          }
        ]
      }

      myChart.setOption(option)
    },
    toExport () {
      // let routeData = this.$router.resolve({
      //   name: 'export'
      // })
      // window.open(routeData.href, '_blank')
      this.$router.push('/statistics/export')
    },
    formatDate (time) {
      if (typeof (time) === 'string' && time.indexOf('T') === -1) {
        time = time.replace(/-/g, '/').substr(0, time.indexOf('.'))
      }
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let min = date.getMinutes()
      let second = date.getSeconds()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (min < 10) {
        min = '0' + min
      }
      if (second < 10) {
        second = '0' + second
      }
      return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
    },

    loadMapChart (myChart, data) {
      var json = mapJSON()
      this.$echarts.registerMap('sc', json, {})
      var option = this.refreshMap(data)
      myChart.setOption(option)
      myChart.hideLoading()
    },
    refreshMap (data) {
      return {
        tooltip: { trigger: 'item',
          formatter: function (data) {
          // console.log(data);
            if (!isNaN(data.value)) {
              return data.name + '：' + data.value + '条'
            } else {
              return data.name + '：' + 0 + '条'
            }
          }
        },
        visualMap: {
          min: data[data.length - 1].value === data[0].value ? 1 : data[data.length - 1].value,
          max: data[0].value,
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          bottom: '30%',
          right: '10%',
          inRange: { color: ['#E3E2E2', '#A5AEC9', '#929FC6', '#8192C5', '#6E84C5', '#6079C6', '#5470C6'] }
        },
        series: [{
          name: '',
          type: 'map',
          mapType: 'sc',
          aspectScale: 0.85,
          itemStyle: {
            normal: {
              borderWidth: 1,
              borderColor: '#FFF',
              label: {
                show: false
              }
            },
            emphasis: {
              borderWidth: 2,
              borderColor: '#FFF',
              areaColor: '#92CC76'
            }
          },
          label: {
            // normal: {show: true, textStyle: {color: '#000'}},
            emphasis: { show: true, textStyle: { color: '#333' } }
          },
          data: data
        }]
      }
    },
    async delhandleClose(tag) {
      this.loadingDiyWord = true
      const res = await delDiyWord(tag)
      this.loadingDiyWord = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.loadDiyWords()
      } else {
        this.$message.error(res.msg)
      }
    },
    delshowInput() {
      this.delinputVisible = true;
      this.$nextTick(_ => {
        this.$refs.delsaveTagInput.$refs.input.focus();
      });
    },
    async delhandleInputConfirm() {
      let inputValue = this.delinputValue
      if (inputValue) {
        this.loadingDiyWord = true
        const res = await addDiyWord({ word: inputValue, type: 1 })
        this.loadingDiyWord = false
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.loadDiyWords()
        } else {
          this.$message.error(res.msg)
        }
      }
      this.delinputVisible = false
      this.delinputValue = ''
    },
    async lockhandleClose(tag) {
      this.loadingDiyWord = true
      const res = await delDiyWord(tag)
      this.loadingDiyWord = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.loadDiyWords()
      } else {
        this.$message.error(res.msg)
      }
    },
    lockshowInput() {
      this.lockinputVisible = true;
      this.$nextTick(_ => {
        this.$refs.locksaveTagInput.$refs.input.focus();
      });
    },
    async lockhandleInputConfirm () {
      let inputValue = this.lockinputValue
      if (inputValue) {
        this.loadingDiyWord = true
        const res = await addDiyWord({ word: inputValue, type: 0 })
        this.loadingDiyWord = false
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.loadDiyWords()
        } else {
          this.$message.error(res.msg)
        }
      }
      this.lockinputVisible = false
      this.lockinputValue = ''
    }
  }
}
</script>

<style lang="scss">
.analysis {
  .dimension-statistics {
    min-width: calc(100vw - 330px);
    min-height: 100%;
    .noData{
      width: 100%;
      text-align: center;
      position: absolute;
      color: #898989;
      top: 180px;
    }
    .filter {
      min-width: calc(100vw - 330px);
      background: #fff;
      box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
      border-radius: 2px;
      padding: 30px;

      .title{
        padding: 8px 0;
        color: #565656;
        font-size: 15px;
        position: relative;
        .el-radio--mini.is-bordered .el-radio__label,
        .el-checkbox__input.is-checked + .el-checkbox__label{
          color: #838383;
        }
        .el-radio.is-bordered.is-checked{
          border: 1px solid #3472c7;
        }
        .el-radio__input.is-checked + .el-radio__label{
          color: #3472c7;
        }
        .el-date-editor{
          position: absolute;
          margin-left: 5px;
        }
      }
      .btn-group {
        float: right;
        margin-top: 5px;
        .el-button {
          height: 24px;
          padding: 5px 15px;
          margin-left: 20px;
        }
      }

      .el-radio.is-bordered {
        margin-right: 0px;
        .el-radio__input {
          display: none;
        }
      }
      .el-input {
        width: 300px;
        .el-input__inner {
          height: 24px;
        }
      }

      .el-radio {
        padding: 6px 15px 0 15px;
        .el-radio__label {
          padding-left: 0;
        }
      }
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .top-wrap{
        border-bottom: 1px solid #eaeaea;
        padding: 15px 20px 0;
      }
      .item:nth-child(odd) {
        width: 49%;
        margin-right: 1%;
        height: 380px;
        background: #fff;
        margin-top: 20px;
        box-shadow:2px 2px 10px 0px rgba(207,207,207,0.5);
        border-radius:2px;
        position: relative;
        .title {
          font-size: 14px;
          color: #666;
          margin-top: 0;
          // margin-bottom: 20px;
        }
        .charts {
          width: 100%;
          height: calc(100% - 60px);
          padding: 20px;
        }
      }
      .item:nth-child(even) {
        width: 49%;
        margin-left: 1%;
        height: 380px;
        background: #fff;
        margin-top: 20px;
        box-shadow:2px 2px 10px 0px rgba(207,207,207,0.5);
        border-radius:2px;
        position: relative;
        .title {
          font-size: 14px;
          color: #666;
          margin-top: 0;
          // margin-bottom: 20px;
        }
        .charts {
          width: 100%;
          height: calc(100% - 60px);
          padding: 20px;
        }
      }
      .dimension-statistics {
        width: 100%;
        height: 800px;
        background: #fff;
        margin-top: 20px;
        box-shadow:2px 2px 10px 0px rgba(207,207,207,0.5);
        border-radius:2px;
        // padding: 20px;
        position: relative;
        .title {
          font-size: 14px;
          color: #666;
          margin-top: 0;
          // margin-bottom: 20px;
        }
        .charts {
          width: 50%;
          height: calc(100%);
          float: left;
          padding: 20px;
        }
      }
    }
  }
  .diy-word-cloud {
    .el-tag {
      margin-left: 10px;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    .button-new-tag {
      margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
}
</style>
<style>
.blur-overlay {
  position: absolute;
  width: calc(100% - 290px);
  height: 2500px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.blur-message {
  border-radius: 10px;
  padding: 20px;
  color: #4471C1;
  font-weight: bold;
  position: absolute;
  top: 200px;
}
</style>
