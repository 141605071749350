<template>
  <div>
    <div style="padding: 20px 20px 0 20px; text-align: right; background: #FFFFFF">
      <el-button type="primary" size="small" @click="$router.push('/perceptionReport/diyReportAdmin')">创建系统内置模板</el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      stripe
      style="width: 100%">
      <el-table-column
        width="150"
        prop="templateName"
        label="模板名称">
      </el-table-column>
      <el-table-column
        prop="circleText"
        label="时间周期">
      </el-table-column>
      <el-table-column
        width="150"
        prop="createDate"
        label="创建日期">
      </el-table-column>
<!--      <el-table-column-->
<!--        width="100"-->
<!--        label="启用">-->
<!--        <template scope="{ row }">-->
<!--          <el-switch-->
<!--            @change="changeEnable(row)"-->
<!--            v-model="row.enable"-->
<!--            :active-value="true"-->
<!--            :inactive-value="false"-->
<!--            active-color="#13ce66"-->
<!--            inactive-color="#ff4949">-->
<!--          </el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
        width="180"
        label="操作">
        <template scope="{ row }">
          <el-button size="small" type="primary" @click="$router.push('/perceptionReport/diyReportAdmin/edit/' + row.id)"><i style="margin-right: 5px" class="el-icon-setting" />编辑</el-button>
<!--          <el-button size="small" type="warning" @click="getAllEmailReceiver(row.id)"><i style="margin-right: 5px" class="el-icon-user" />接收人</el-button>-->
<!--          <el-button size="small" type="success" @click="showCreateNowDialog(row.id)"><i style="margin-right: 5px" class="el-icon-video-play" />引用生成</el-button>-->
          <el-button size="small" type="danger" @click="del(row)"><i style="margin-right: 5px" class="el-icon-delete" />删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="提示"
      :visible.sync="delDialog"
      width="20%">
      <span>您确认要删除该模板吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmDel">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="引用生成"
      :visible.sync="createNowDialog"
      width="30%">
      <div>
        <div style="margin-bottom: 20px">请选择生成报告的数据范围区间</div>
        <el-date-picker
          style="width: 100%"
          size="small"
          v-model="dateRange"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="createNowDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmCreateNow">立即生成</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="设置接收人"
      :visible.sync="setReceiverDialog"
      width="30%">
      <div>
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="邮箱" name="email" v-loading="emailLoading">
            <div>
              <div style="margin-bottom: 15px">系统账户</div>
              <div style="height: 300px; overflow: auto">
              <el-table
                :data="emailReciverArray"
                border
                style="width: 100%;">
                <el-table-column
                  prop="accountType"
                  label="类型"
                  width="80">
                </el-table-column>
                <el-table-column
                  prop="pushAddress"
                  label="地址">
                </el-table-column>
                <el-table-column
                  prop="account"
                  width="130"
                  label="账号">
                </el-table-column>
                <el-table-column
                  width="70"
                  label="接收">
                  <template scope="{ row }">
                    <el-switch
                      v-model="row.enable"
                      :active-value="true"
                      :inactive-value="false"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
                    </el-switch>
                  </template>
                </el-table-column>
              </el-table>
              </div>
              <div style="margin-bottom: 15px; margin-top: 15px">自定义</div>
              <el-input
                type="textarea"
                :rows="5"
                placeholder="请输入接收邮箱，回车换行添加多个邮箱"
                v-model="diyEmailReceiver">
              </el-input>
            </div>
          </el-tab-pane>
          <el-tab-pane label="微信" name="wechat">
            <div>微信接收功能开发中...</div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="setReceiverDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="updateReceiver">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listTemplates, switchTemEnable, delTem, createNow, getAllEmailReceiver, updateReceiver } from '@/api/diy'
export default {
  name: 'templates',
  data () {
    return ({
      loading: false,
      tableData: [],
      delDialog: false,
      delId: 0,
      dateRange: [],
      createNowDialog: false,
      createNowId: 0,
      activeName: 'email',
      setReceiverDialog: false,
      setReceiverTemId: 0,
      diyEmailReceiver: '',
      emailReciverArray: [],
      emailLoading: false
    })
  },
  methods: {
    async initData () {
      this.loading = true
      const res = await listTemplates()
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].circle === 1) {
          res.data[i].circleText = JSON.parse(res.data[i].dateRangeStr).join(' - ')
        } else {
          if (res.data[i].circleType === 'week') {
            switch (res.data[i].circleValue) {
              case 1:
                res.data[i].circleText = '于每周一'
                break
              case 2:
                res.data[i].circleText = '于每周二'
                break
              case 3:
                res.data[i].circleText = '于每周三'
                break
              case 4:
                res.data[i].circleText = '于每周四'
                break
              case 5:
                res.data[i].circleText = '于每周五'
                break
              case 6:
                res.data[i].circleText = '于每周六'
                break
              case 7:
                res.data[i].circleText = '于每周日'
                break
            }
          } else if (res.data[i].circleType === 'day') {
            res.data[i].circleText = '于每天' + res.data[i].circleValue + '点'
          } else if (res.data[i].circleType === 'month') {
            switch (res.data[i].circleValue) {
              case 1:
                res.data[i].circleText = '于每月1日'
                break
              case 2:
                res.data[i].circleText = '于每月5日'
                break
              case 3:
                res.data[i].circleText = '于每月10日'
                break
              case 4:
                res.data[i].circleText = '于每月15日'
                break
              case 5:
                res.data[i].circleText = '于每月20日'
                break
              case 6:
                res.data[i].circleText = '于每月25日'
                break
              case 7:
                res.data[i].circleText = '于每月月底'
                break
            }
          } else if (res.data[i].circleType === 'year') {
            res.data[i].circleText = '于每年' + res.data[i].circleMonth + '月'
            switch (res.data[i].circleValue) {
              case 1:
                res.data[i].circleText += '1日'
                break
              case 2:
                res.data[i].circleText += '5日'
                break
              case 3:
                res.data[i].circleText += '10日'
                break
              case 4:
                res.data[i].circleText += '15日'
                break
              case 5:
                res.data[i].circleText += '20日'
                break
              case 6:
                res.data[i].circleText += '25日'
                break
              case 7:
                res.data[i].circleText += '月底'
                break
            }
          }
        }
      }
      this.tableData = res.data
      this.loading = false
    },
    async changeEnable (row) {
      const res = await switchTemEnable({ id: row.id, enable: row.enable })
      if (res.code === 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
        row.enable = !row.enable
      }
    },
    del (row) {
      this.delId = row.id
      this.delDialog = true
    },
    async confirmDel () {
      this.delDialog = false
      this.loading = true
      const res = await delTem({ id: this.delId })
      this.loading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.initData()
      } else {
        this.$message.error(res.msg)
      }
    },
    showCreateNowDialog (id) {
      this.createNowDialog = true
      this.createNowId = id
    },
    async confirmCreateNow () {
      if (this.dateRange.length < 2) {
        this.$message.warning('请选择时间范围')
        return
      }
      this.createNowDialog = false
      const res = await createNow({ id: this.createNowId, startDate: this.dateRange[0], endDate: this.dateRange[1] })
      if (res.code === 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    async getAllEmailReceiver (id) {
      this.emailLoading = true
      this.setReceiverTemId = id
      this.setReceiverDialog = true
      const res = await getAllEmailReceiver({ id: this.setReceiverTemId })
      this.emailLoading = false
      if (res.code === 0) {
        this.diyEmailReceiver = res.data.diyEmailReceiver
        this.emailReciverArray = res.data.emailSysUser
      }
    },
    async updateReceiver () {
      this.emailLoading = true
      const res = await updateReceiver({ temId: this.setReceiverTemId, diyEmailReceiver: this.diyEmailReceiver, emailSysUser: this.emailReciverArray })
      this.emailLoading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.setReceiverDialog = false
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  mounted () {
    this.initData()
  }
}
</script>

<style scoped>

</style>
