import { render, staticRenderFns } from "./templatesAdmin.vue?vue&type=template&id=3f9dbbe2&scoped=true&"
import script from "./templatesAdmin.vue?vue&type=script&lang=js&"
export * from "./templatesAdmin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f9dbbe2",
  null
  
)

export default component.exports