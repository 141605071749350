<template>
  <section>
    <div class="tabs" v-if="anaShow">
      <img src="@/assets/images/icons/icon-list.png">
      <span class="name">{{ currentMonitor.name }}</span>
      <div class="tab">
        <label style="cursor: pointer" :class="isChildUpdate1?'active':''" @click="handleClick1">数据列表</label>
        <label style="cursor: pointer" :class="isChildUpdate2?'active':''" @click="handleClick2">统计分析</label>
      </div>
    </div>

    <detectionData v-if="isChildUpdate1"/>
    <detectionAnaly v-if="isChildUpdate2"/>
  </section>
</template>

<script>
import detectionData from './list.vue'
import detectionAnaly from './analysis.vue'

export default {
  components: {
    detectionData,
    detectionAnaly
  },
  data () {
    return {
      anaShow: false,
      activeName: 'dataList',
      isChildUpdate1: true,
      isChildUpdate2: false,
      currentMonitor: {}
    }
  },
  mounted () {
    this.currentMonitor = JSON.parse(sessionStorage.getItem(localStorage.getItem('ct_account') + 'currentMonitor'))
    if (this.$route.params.nodeId === '0') {
      this.anaShow = false
    } else {
      this.anaShow = true
    }
    console.log(this.anaShow)
  },
  methods: {
    handleClick1 () {
      this.isChildUpdate1 = true
      this.isChildUpdate2 = false
    },
    handleClick2 () {
      this.isChildUpdate1 = false
      this.isChildUpdate2 = true
    }
  },
  computed: {
    // 监听数据
    getnewValue () {
      return this.$route.path
    }
  },
  watch: {
    //  监听改变事件
    getnewValue: {
      handler (newValue, oldValue) {
        if (newValue.split('/')[4] === '0') {
          this.anaShow = false
        } else {
          this.anaShow = true
          this.currentMonitor = JSON.parse(sessionStorage.getItem(localStorage.getItem('ct_account') + 'currentMonitor'))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  min-width: calc(100vw - 330px);
  background-color: #fff;
  border-radius: 2px;
  padding: 18px 22px;
  margin-bottom: 15px;

  img {
    width: 18px;
    vertical-align: text-bottom;
  }

  .name {
    font-size: 17px;
    margin: 0 5px;
    color: #565656;
  }

  .tab {
    float: right;

    label {
      color: #838383;
      font-size: 16px;
      margin: 0 10px;

      &.active {
        color: #3472c7;
      }
    }
  }
}

.el-tabs__content {
  overflow-x: auto;
}
</style>
